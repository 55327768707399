<template>
  <auth-layout>
    <form>
      <card-item :margin="true">
        <ab-input-field
          :label="$t('forms.email')"
          v-model="email"
          type="email"
          :error="$v.email.$error"
          :errorMessage="$t('forms.valid_email')"
        ></ab-input-field>
        <div class="login__error" v-if="$v.email.$error">
          <p>{{ submitStatus }}</p>
        </div>
      </card-item>
      <div class="spacer--20"></div>
      <div class="grid-x align-middle">
        <div class="cell auto">
          <router-link to="/login">{{
              $t('forms.return_to_login')
            }}
          </router-link>
        </div>
        <div class="cell auto text-right">
          <button type="submit" @click="formSubmit" class="button--primary">
            {{ $t('forms.password_reset') }}
          </button>
        </div>
      </div>
    </form>
  </auth-layout>
</template>

<script>
import AuthLayout from '../../layouts/AuthLayout'
import backend from '@/backend'
import { required, email } from 'vuelidate/lib/validators'

export default {
  name: 'PasswordForgotten',
  data () {
    return {
      email: '',
      submitStatus: null
    }
  },
  validations: {
    email: {
      required,
      email
    }
  },

  methods: {
    formSubmit (e) {
      e.preventDefault()
      this.submitStatus = null
      this.$v.$touch()
      if (!this.$v.$invalid) {
        setTimeout(() => {
          backend
            .post(`${process.env.VUE_APP_URL}/users/forgot_password`, {
              email: this.email
            })
            .then(() => {
              this.$router.push('/verify-password')
            })
            .catch(() => {
              this.submitStatus = this.$t('forms.email')
            })
        }, 500)
      }
    }
  },
  components: {
    'auth-layout': AuthLayout
  }
}
</script>
